jQuery(document).ready(function ($) {
  $(":password")
    .parent()
    .addClass("form__password")
    .append('<i class="fas fa-eye"></i>');

  // --------------------------------------------- PASSWORD
  $(document).on("click", ".fa-eye", function (e) {
    var $pass = $(e.target).parent().find("input");
    $(e.target).toggleClass("fa-eye-slash");
    if ($pass.attr("type") === "password") {
      $pass.attr("type", "text");
    } else {
      $pass.attr("type", "password");
    }
  });
  // --------------------------------------------- EO PASSWORD

  // --------------------------------------------- SEARCH

  // var $searchInput = $(".header__search input"),
  //   $searchClear = $(".header__search .search__clear");

  // $searchInput.keyup(function () {
  //   $searchClear.show();
  //   if ($(this).val() == "") {
  //     $searchClear.hide();
  //   }
  // });

  // $searchClear.click(function () {
  //   $searchInput.val("");
  //   $(this).hide();
  // });

  // --------------------------------------------- EO SEARCH
});
