jQuery(function ($) {
  //------------------------------------------------------ Bouton menu click
  $("#admin__menu__btn").on("click", function () {
    $(".admin__menu").toggleClass("admin__menu--active");
    $(this).toggleClass("menu__btn--active");
    // $("body").toggleClass("body--active");
  });

  $(window).scroll(function () {
    $(".admin__menu").removeClass("admin__menu--active");
    $("#admin__menu__btn").removeClass("menu__btn--active");
  });
});
