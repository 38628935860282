jQuery(document).ready(function ($) {
  $(".item-107").click(function () {
    $(".hika__category__menu").toggleClass("hika__category__menu--active");
    $("body").toggleClass("body--active");
  });

  $(".hika__category__close").click(function () {
    $(".hika__category__menu").removeClass("hika__category__menu--active");
    $("body").removeClass("body--active");
  });

  $(document).on("mouseup", function (e) {
    if (!e.target.closest(".hika__category__menu")) {
      $(".hika__category__menu").removeClass("hika__category__menu--active");
      $("body").removeClass("body--active");
    }
  });

  // ================================================================== MENU CATEGORY

  $(".parent__category").click(function () {
    $(this).parent().find("ul").first().addClass("category--active");

    $(".menu__body ul").each(function () {
      if ($(this).hasClass("category--active")) {
        $(".menu__lvl--1").css("height", $(this).height());
      }
    });
  });
  $(".category__back").click(function () {
    $(".menu__body ul").removeAttr("style").removeClass("category--active");
  });

  // ================================================================== EO MENU CATEGORY
});
