// var jQuery = require("jquery");
jQuery(document).ready(function ($) {
  $(window).load(function () {
    $("#status").fadeOut();
    $("#preloader").delay(350).fadeOut(500);
    $("body").delay(350).css({
      overflow: "visible",
    });
  });

  //   =========================================================================== VIDEO
  $(window).load(function () {
    const player = new Plyr("#player", {
      controls: [
        "play-large",
        "play",
        "progress",
        "mute",
        "volume",
        "fullscreen",
      ],
    });

    $(".btn__video--1").click(function () {
      $(".acc__video--1").toggleClass("acc-video-active");
      $(".acc-close").toggleClass("acc-close-active");
      player.play();
    });

    $(".acc-close").click(function () {
      $(".acc-video").removeClass("acc-video-active");
      $(this).removeClass("acc-close-active");
      player.stop();
    });

    const player2 = new Plyr("#player-2", {
      controls: [
        "play-large",
        "play",
        "progress",
        "mute",
        "volume",
        "fullscreen",
      ],
    });

    $(".btn__video--2").click(function () {
      $(".acc__video--2").toggleClass("acc-video-active");
      $(".acc-close").toggleClass("acc-close-active");
      player2.play();

      $(".acc-close").click(function () {
        $(".acc-video").removeClass("acc-video-active");
        $(this).removeClass("acc-close-active");
        player2.stop();
      });
    });

    const player3 = new Plyr("#player-3", {
      controls: [
        "play-large",
        "play",
        "progress",
        "mute",
        "volume",
        "fullscreen",
      ],
    });

    $(".btn__video--3").click(function () {
      $(".acc__video--3").toggleClass("acc-video-active");
      $(".acc-close").toggleClass("acc-close-active");
      player3.play();

      $(".acc-close").click(function () {
        $(".acc-video").removeClass("acc-video-active");
        $(this).removeClass("acc-close-active");
        player3.stop();
      });
    });
  });

  //   =========================================================================== EO VIDEO

  resa();

  function resa() {
    var idPresta = $("#presta_id").html();
    var nbPresta = $("#fox-m184-dropdown1").find("option").length;
    var selectPresta = $(".chzn-container").find("span");

    var i = 1;

    for (i; i < nbPresta; i++) {
      if (idPresta == i) {
        var optionPrestaId = $("#fox-m184-dropdown1").find(
          "option:eq(" + i + ")"
        );
        var optionPrestaHtml = optionPrestaId.html();

        selectPresta.html(optionPrestaHtml);
        optionPrestaId.prop("selected", true);
      }
    }
  }

  $(window).on("scroll", function () {
    var $owl = $(".a-slide").find(".owl-carousel");
    var $posHtml = $("html").offset().top;
    var $menuPrestation = $(".menu-prestations-active");
    var $beauteNaturelle = $("#beaute-naturelle");

    if ($beauteNaturelle.length) {
      var $menuPrestationFixed = $beauteNaturelle.offset().top + 70;
      var $prestationActive = $(".menu-prestations-active");

      if ($(this).scrollTop() > $menuPrestationFixed) {
        $prestationActive.addClass("active-fixed");
      } else {
        $prestationActive.removeClass("active-fixed");
      }
    }

    if ($(this).scrollTop() > 400) {
      $(".btn__top").addClass("btn__top-active");
      $("body").addClass("body-active");
      $(".header").addClass("header-active");
      $(".footer").addClass("f-mentions-active");
      $owl.addClass("owl-carousel-active");
    } else {
      $(".btn__top").removeClass("btn__top-active");
      $("body").removeClass("body-active");
      $(".header").removeClass("header-active menu-active");
      $(".footer").removeClass("f-mentions-active");
      $(".a-slide").find(".owl-carousel").removeClass("owl-carousel-active");
      $(".menu-mob")
        .find("i")
        .removeClass("fa-times")
        .addClass("fa-ellipsis-h");
    }
  });

  //   if ($("body").hasClass("page-121")) {
  //     new AnimOnScroll(document.getElementById("grid"), {
  //       minDuration: 0.4,
  //       maxDuration: 0.7,
  //       viewportFactor: 0.2,
  //     });
  //   }
  $(window).enllax();

  $(window).load(function () {
    $(".img-avant-apres").twentytwenty();
  });

  if ($("body").hasClass("page-133")) {
    var fileInput = document.querySelector("#fichier");
    var the_return = document.querySelector("#return");
    fileInput.addEventListener("change", function (event) {
      the_return.innerHTML = this.value.replace(/C:\\fakepath\\/i, "");
    });
  }

  if ($("body").hasClass("page-134")) {
    var fileInput = document.querySelector("#fichier1");
    var the_return = document.querySelector("#return1");
    fileInput.addEventListener("change", function (event) {
      the_return.innerHTML = this.value.replace(/C:\\fakepath\\/i, "");
    });
  }

  if ($("body").hasClass("page-134")) {
    var fileInput2 = document.querySelector("#fichier2");
    var the_return2 = document.querySelector("#return2");
    fileInput2.addEventListener("change", function (event) {
      the_return2.innerHTML = this.value.replace(/C:\\fakepath\\/i, "");
    });
  }
  //   $(".btn-h, .menu-prestations-active a, .btn-prestige, .page-link-txt").on(
  //     "click",
  //     function (e) {
  //       var $anchor = $(this);
  //       $("html, body")
  //         .stop()
  //         .animate(
  //           {
  //             scrollTop: $($anchor.attr("href")).offset().top - 70,
  //           },
  //           1000
  //         );
  //       e.preventDefault();
  //     }
  //   );

  //   $(".menu-prestations")
  //     .find("a")
  //     .on("click", function (e) {
  //       var $anchor = $(this);
  //       $("html, body")
  //         .stop()
  //         .animate(
  //           {
  //             scrollTop: $($anchor.attr("href")).offset().top - 300,
  //           },
  //           1000
  //         );
  //       e.preventDefault();
  //     });

  $(".menu-mob").on("click", function () {
    $(".header").toggleClass("menu-active");
    $(this).find("i").toggleClass("fa-ellipsis-h fa-times");
    $(".arrow-menu").removeClass("arrow-active");
  });

  $(".a-slide").find(".owl-carousel").owlCarousel({
    loop: true,
    items: 1,
    autoplay: true,
    nav: false,
    dots: false,
    animateOut: "fadeOut",
    smartSpeed: 300,
    autoplayHoverPause: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
  });

  $("#mes-prestations").find(".owl-carousel").owlCarousel({
    items: 5,
    autoWidth: true,
    margin: 35,
    loop: true,
    autoplay: true,
    autoplayTimeout: 2000,
    smartSpeed: 1000,
    autoplayHoverPause: true,
    nav: true,
  });

  $(window).load(function () {
    $("#player").contents().find(".ytp-chrome-top").html("");
  });
});
