(function ($) {
  var $giftCard = $(
    ".hikashop_characteristic_line_40,.hikashop_characteristic_line_35,.hikashop_characteristic_line_45,.hikashop_characteristic_line_52,.hikashop_characteristic_line_56"
  );

  $giftCard.find(".inputbox:checked").parent().addClass("active");

  $giftCard.find("label").on("click", function () {
    $(this).parent().find("label").removeClass("active");
    $(this).addClass("active");
  });
})(jQuery);
